import React, { useEffect, useRef, useState } from "react";
import Img from "gatsby-image/withIEPolyfill";
import { FluidObject } from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";

import "./Gallery.scss";
/**
 * Gallery.tsx
 *
 * Component for the full screen Gallery component in Event Details Page.
 *
 */

/**
 * GalleryProps
 * Component Props
 */
interface Image {
  alt: string;
  src: FluidObject;
}
interface GalleryProps {
  images: Image[];
}

/**
 * Gallery
 * Component Proper
 */
export const Gallery: React.FC<GalleryProps> = ({ images }) => {
  /**
   * Part 1: NON-FULLSCREEN VIEW - VARIABLES AND FUNCTIONS
   */

  // For the gallery container that scrolls
  const scrollOffsetRef = useRef(300); // how much the gallery scrolls left/right in pixels
  const [containersDiff, setContainersDiff] = useState(0); // difference between the widths of the outer container and inner (scrollable) container in pixels
  const [translateXValue, setTranslateXValue] = useState(0); // a.k.a. the scroll position
  const [touchStart, setTouchStart] = useState({
    currentTranslateValue: 0,
    touchStartX: -1,
  }); // for touch screen scrolling

  const gallery_scroll_container_ref = useRef<HTMLDivElement>(null); // container for the 'inner element'
  const gallery_bar_container_ref = useRef<HTMLDivElement>(null); // the 'inner element' that should scroll

  /**
   * Click listener for left arrow button of gallery carousel
   */
  const handleLeftArrowClick = () => {
    const barContainerWidth = gallery_bar_container_ref.current?.scrollWidth;
    const scrollContainerWidth = gallery_scroll_container_ref.current?.getBoundingClientRect()
      .width;
    setTranslateXValue((x) => {
      const newTranslateXVal = x + scrollOffsetRef.current;

      if (barContainerWidth && scrollContainerWidth) {
        // Bar is shorter than the scroll container -> no scroll
        if (containersDiff > 0 || newTranslateXVal > 0) return 0;
      }
      return newTranslateXVal;
    });
  };

  /**
   * Click listener for right arrow button of gallery carousel
   */
  const handleRightArrowClick = () => {
    const barContainerWidth = gallery_bar_container_ref.current?.scrollWidth;
    const scrollContainerWidth = gallery_scroll_container_ref.current?.getBoundingClientRect()
      .width;
    setTranslateXValue((x) => {
      const newTranslateXVal = x - scrollOffsetRef.current;

      if (barContainerWidth && scrollContainerWidth) {
        // Bar is shorter than the scroll container -> no scroll
        if (containersDiff > 0) return 0;
        // Bar is longer than the scroll container -> has scroll but limit to containers diff.
        if (newTranslateXVal <= containersDiff) return containersDiff;
      }

      return newTranslateXVal;
    });
  };

  /**
   * Window resize listener to get the updated width values of gallery containers
   */
  useEffect(() => {
    const updateTranslateBounds = () => {
      const barContainerWidth = gallery_bar_container_ref.current?.scrollWidth;
      const scrollContainerWidth = gallery_scroll_container_ref.current?.getBoundingClientRect()
        .width;
      if (barContainerWidth && scrollContainerWidth) {
        const diff = scrollContainerWidth - barContainerWidth;
        setContainersDiff(diff);
        // If the current `translateXValue` exceeds the allowable limit (which is between `diff` and 0), set it to `diff`
        setTranslateXValue(
          translateXValue < diff && diff !== 0 ? diff : translateXValue
        );
      }

      // Set scroll offset based on gallery item size
      const galleryItem = document.querySelector(".event-gallery-item");
      scrollOffsetRef.current =
        (galleryItem && galleryItem.getBoundingClientRect().width) || 300;
    };

    // Sets the initial/update the scroll amount bounds of the gallery carousel
    window.addEventListener("resize", updateTranslateBounds);
    updateTranslateBounds();
    return () => window.removeEventListener("resize", updateTranslateBounds);
  }, [translateXValue]);

  /**
   * Part 1: FULLSCREEN VIEW - VARIABLE
   */
  // The index of the image (based on the props 'images') that is currently shown on the full screen view. -1 means the full screen view isn't shown.
  const [shownImage, setShownImageIndex] = useState(-1);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case "Escape":
          setShownImageIndex(-1);
          break;
        case "ArrowLeft":
          if (shownImage > 0) setShownImageIndex((i) => i - 1);
          break;
        case "ArrowRight":
          if (shownImage > -1 && shownImage < images.length - 1)
            setShownImageIndex((i) => i + 1);
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [shownImage]);

  return (
    <>
      {/* NON-FULLSCREEN GALLERY VIEW (i.e. the carousel view) */}
      <h2 id="gallery-heading">Gallery</h2>

      {/* The 'outer container' */}
      <div id="gallery-controls">
        <button
          disabled={translateXValue === 0}
          id="left-arrow"
          onClick={handleLeftArrowClick}
        >
          <FontAwesomeIcon size="1x" icon={faChevronLeft} />
        </button>
        <button
          disabled={translateXValue === containersDiff}
          id="right-arrow"
          onClick={handleRightArrowClick}
        >
          <FontAwesomeIcon size="1x" icon={faChevronRight} />
        </button>
        <div
          ref={gallery_scroll_container_ref}
          id="event-gallery-scroll-container"
          // Scroll listener for touch screens
          onTouchStart={(e) => {
            setTouchStart({
              currentTranslateValue: translateXValue,
              touchStartX: e.touches[0].clientX,
            });
          }}
          onTouchMove={(e) => {
            const touchXOffset = touchStart.touchStartX - e.touches[0].clientX;
            let newTranslateXVal =
              touchStart.currentTranslateValue - touchXOffset * 1.618;
            // newTranslateXVal should stay within allowable bounds
            newTranslateXVal =
              newTranslateXVal < containersDiff
                ? containersDiff
                : newTranslateXVal;
            newTranslateXVal = newTranslateXVal > 0 ? 0 : newTranslateXVal;
            setTranslateXValue(newTranslateXVal);
          }}
          onTouchEnd={() => {
            setTouchStart({
              currentTranslateValue: 0,
              touchStartX: -1,
            });
          }}
        >
          {/* The 'inner container' */}
          <div
            ref={gallery_bar_container_ref}
            id="event-gallery-bar-container"
            style={{ transform: `translateX(${translateXValue}px)` }}
          >
            {images.map((image: Image, i: number) => (
              <div
                className="event-gallery-item"
                key={i}
                onClick={() => setShownImageIndex(i)}
              >
                <div className="event-gallery-item-expand">
                  <FontAwesomeIcon size="1x" icon={faExpandArrowsAlt} />
                </div>
                <Img
                  fluid={image.src}
                  alt={image.alt}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FULL SCREEN GALLERY VIEW */}
      <div
        id="gallery-fullscreen"
        style={{
          display: shownImage === -1 ? "none" : "flex",
        }}
      >
        <button
          id="gallery-fullscreen-close"
          onClick={() => setShownImageIndex(-1)}
        >
          <FontAwesomeIcon size="1x" icon={faTimes} />
        </button>
        <div id="gallery-fullscreen-controls">
          {/* Fullscreen Image */}
          <div id="gallery-fullscreen-image-container">
            {shownImage > -1 && shownImage < images.length && (
              <Img
                objectFit="contain"
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
                fluid={images[shownImage].src}
                alt={images[shownImage].alt}
              />
            )}
          </div>

          {/* Previous/Next Buttons */}
          <button
            id="gallery-fullscreen-navigation-left"
            disabled={shownImage === 0}
            onClick={() => setShownImageIndex(shownImage - 1)}
          >
            <FontAwesomeIcon size="1x" icon={faChevronLeft} />
          </button>
          <button
            id="gallery-fullscreen-navigation-right"
            disabled={shownImage === images.length - 1}
            onClick={() => setShownImageIndex(shownImage + 1)}
          >
            <FontAwesomeIcon size="1x" icon={faChevronRight} />
          </button>
        </div>
      </div>
    </>
  );
};
