import React from "react";
import "./ProjectDetails.scss";
import Img, { FluidObject } from "gatsby-image";
import { graphql, navigate } from "gatsby";
import { Gallery } from "../Gallery";
import { Layout } from "../Layout";
import { SEO } from "../SEO";
import ProjectDeveloper from "./ProjectDeveloper";

/**
 * ProjectDetails.tsx
 *
 * Component for the details of an event in Events and Activities page.
 *
 */

/**
 * ProjectDetailsProps
 * Component Props
 */
interface EventDetailProps {
  title: string;
  description: string;
  gallery: FluidObject[];
  data: any;
  location: any;
}

/**
 * ProjectDetails
 * Component Proper
 */
const ProjectDetails: React.FC<EventDetailProps> = ({ data, location }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  const images = frontmatter.galleryPhotos.map((img: any, i: number) => ({
    src: img.childImageSharp.fluid,
    alt: `${frontmatter.title} - Gallery Photo #${i + 1}`,
  }));

  return (
    <Layout>
      <SEO title={`${frontmatter.title} | Projects - ACSS UPLB`} />
      <main id="project-details">
        <header>
          <div id="back-navigation">
            <a
              onClick={() => {
                const initialLanding = location.key === "initial";
                if (initialLanding) navigate("/projects");
                else navigate(-1);
              }}
            >
              {/* Arrow Left (Back) */}
              <svg
                height="16"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 5H13"
                  stroke="#C1CDDA"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 1L1 5L5 9"
                  stroke="#C1CDDA"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Go back to Projects</span>
            </a>
          </div>
          <div id="hero-image">
            <Img
              fluid={frontmatter.heroImage.childImageSharp.fluid}
              alt={`-ProjectTitle- Hero Image`}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <h1 id="project-title">{frontmatter.title}</h1>
        </header>
        <section id="details-section">
          <h2 id="project-description-heading">Description</h2>
          <p
            id="project-description-text"
            dangerouslySetInnerHTML={{ __html: html }}
          ></p>
          <Gallery images={images} />
          <h2 id="project-description-heading">Developers</h2>
          <div id="project-developers">
            {frontmatter.developerPhotos.map((img: any, i: number) => (
              <div className="project-developer-card-container" key={i}>
                <ProjectDeveloper
                  image={img.childImageSharp.fluid}
                  alt={`${frontmatter.title} - Developer Photo #${i + 1}`}
                  name={frontmatter.developers[i]}
                  role={frontmatter.roles[i]}
                  hasLinkedIn={frontmatter.hasLinkedIn[i]}
                  linkedIn={frontmatter.linkedIn[i]}
                />
              </div>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ProjectDetails;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        developers
        roles
        hasLinkedIn
        linkedIn
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galleryPhotos {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        developerPhotos {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
