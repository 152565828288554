import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Img, { FluidObject } from "gatsby-image";
import React from "react";

import "./ProjectDeveloper.scss";

interface ProjectDeveloperProps {
  image: FluidObject;
  alt: string;
  name: string;
  role: string;
  hasLinkedIn?: boolean;
  linkedIn: string;
}

const ProjectDeveloper: React.FC<ProjectDeveloperProps> = ({
  image,
  alt,
  name,
  role,
  hasLinkedIn = true,
  linkedIn,
}) => {
  return (
    <div className="project-developer-card">
      <div className="project-developer-image">
        <Img fluid={image} alt={alt} draggable={false} />
      </div>
      <div className="project-developer-card-texts">
        <h4>{name}</h4>
        <h4 className={"project-developer-role"}>{role}</h4>
        {hasLinkedIn && (
          <h4 className={"project-developer-linkedin-header"}>
            LinkedIn Profile
          </h4>
        )}
        {hasLinkedIn && (
          <a href={linkedIn} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon size="2x" icon={faLinkedin} />
          </a>
        )}
      </div>
    </div>
  );
};

export default ProjectDeveloper;
